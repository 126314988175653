*{
  padding: 0;
  margin: 0;
}
:root{
  --themeclr: #092e87;
  --secondclr: #3191db;
  --bluetxt: #000128;
  --graytxt: #6d757f;
  --aqua: #28d4a3;
}
body {
 /* background-color: #e6efff; */
 font-family: 'Montserrat', sans-serif;
}
.themeclr{
  color: var(--themeclr)
}
.disabled.disableele {
  color: #b9b9b9;
  pointer-events: none;
}
p {
  font-size: 15px;
}
a{
  text-decoration: none;
}
.jc-between{
  justify-content: space-between;
}
.jc-around{
  justify-content: space-around;
}
li{
  list-style-type: none;
}
ul{
  padding-left: 0;
}
.fw-400{
  font-weight: 400;
}
.fw-500{
  font-weight: 500;
}
.fw-600{
  font-weight: 600;
}
.fw-700{
  font-weight: 700;
}
.f-12{
  font-size: 12px;
}
.f-14{
  font-size: 14px;
}
.f-15{
  font-size: 15px;
}
.f-16{
  font-size: 16px;
}
.noshadow-on-focus:focus{
  box-shadow: none;
}
.f-18{
  font-size: 18px;
}
.w-full{
  width: 100%;
}
.w-35{
  width: 35%;
}
.h-44 input{
  height: 44px;
}
.br-10{
  border-radius: 10px;
}
.br-15{
  border-radius: 15px;
}
.bluetxt{
  color: var(--bluetxt);
}
.txtdarkblue{
  color:#092e87
}
.themebg{
  background-color: var(--secondclr);
}
.bgblue {
  background: #3191db;
}
.graytxt{
  color: var(--graytxt);
}
.aqua{
  color: var(--aqua)
}
.upper{
  text-transform: uppercase;
}
.transbtn {
  background: transparent;
  border: none;
}
.nounder, .nounder button{
  text-decoration: none;
}
.showin991{
  display: none;
}
.btn.btn-theme {
  background: var(--themeclr);
  color: white;
  border-radius: 30px;
  padding: 2px 16px;
  border: 1px solid var(--themeclr);
  font-size: 14px;
}
.btn.btn-theme-bord{
  color: var(--themeclr);
  border-radius: 30px;
  padding: 2px 16px;
  border: 1px solid var(--themeclr);
  font-size: 14px;
  background-color: #fff;
}
button.btn.btn-theme-bord:hover{
  color: #fff;
  background-color: var(--themeclr);
}
.btn.btn-theme:hover {
  background: transparent;
  color: var(--themeclr);
}
.noshadow input:focus,
.noshadow select:focus,.signin input:focus,.signup input:focus{
  box-shadow: none;
}
.container.container-theme {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.income .security li {
  margin-bottom: 5px;
}
/* loader */
#loader{
  display: none;
}
#loader img {
  width: 50px;
}
#loader.show {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000c2;
  z-index: 99999;


}

/* admin */
.login{
  background-color: #dce3f3;
}

.profile h6{
  font-weight: 700;
  color: var(--graytxt);
}
#codeswitch{
  width: 50px;
}
.history ul li{
  margin-bottom: 15px;
}
.graybox.in .currency {
  /* background: #f4fff1;
  border: 1px solid #c2edc2; */
  color: green;
}
.graybox.out .currency{
  /* border: 1px solid #edc2ce;
  background: #fff1f4; */
  color:#cf0a0a
}
.history img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: gray;
  margin-right: 4px;
}
.transhistory{
  min-height: calc(100vh - 50px);
  overflow: auto;
}
.selectdrop > div {
  width: 100%;
}
.selectdrop  img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
.selectdrop button:hover, .selectdrop button:focus,
.selectdrop.show>.btn-success.dropdown-toggle {
  background: #dce3f3;
  border-color: #dce3f3;
  color: #000;
  box-shadow: none;
}
.selectdrop button{
   width: 100%;
   border-radius: 30px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #dce3f3;
    color: #000;
    height: 30px;
    border-color:#dce3f3;
}
.viewuser div[role = "table"]> div:first-child {
  color: #092e87 !important;
  font-weight: 800;
}
.viewdata > div:last-child span:first-child {
  display: none;
}
.viewdata #pagination-first-page[aria-disabled ="false"]:hover,.viewdata #pagination-last-page[aria-disabled ="false"]:hover,
.viewdata #pagination-previous-page[aria-disabled ="false"]:hover,.viewdata #pagination-next-page[aria-disabled ="false"]:hover{
  background: #092e87;
}
.viewdata #pagination-first-page[aria-disabled ="false"]:hover svg,.viewdata #pagination-last-page[aria-disabled ="false"]:hover svg,
.viewdata #pagination-previous-page[aria-disabled ="false"]:hover svg,.viewdata #pagination-next-page[aria-disabled ="false"]:hover svg{
  fill: #fff;
}
.viewdata button[aria-label = "Previous Page"][aria-disabled ="false"] {
  background: #092e87;
}
.viewdata button[aria-label = "Previous Page"][aria-disabled ="false"] svg{
  fill: #fff
}
.viewdata button[aria-label = "Next Page"][aria-disabled ="false"] {
  background: #092e87;
}
.viewdata button[aria-label = "Next Page"][aria-disabled ="false"] svg{
  fill: #fff
}
.viewdata div[role="table"] > div:first-child div[role="row"] {
  background: #3191db;
  color: #fff;
}
 button.hamburger {
  width: 46px;
}
.viewdata {
  border-radius: 15px;
  overflow: hidden;
  background: #fff;
}
.viewdata div[role="gridcell"], .viewdata div[role="columnheader"] {
  justify-content: center;
}
/* header */
.search span {
  background: white;
  border-left: 0;
  border-radius: 30px;
}
.search input {
  border-radius: 30px;
  border-right: 0;
}
.search input:focus {
  box-shadow: none;
}
.profimg img{
  width: 40px;
  height: 40px;
}
.b-50{
  border-radius: 50%;
}
#header {
  background: var(--secondclr);
  color: #fff;
  position: fixed;
  width: 100%;
  z-index: 9;
}
#header > .d-flex {
  height: 72px;
}
div#header > .d-flex:after {
  content: "";
  background: #031d7a;
  position: absolute;
  height: 72px;
  width: 25%;
  z-index: -1;
  /* transition: 0.5s; */
}
div#header .hamexp:after {
  max-width: 220px;
}
div#header .hamcomp:after {
  max-width: 50px;
}

/* sidebar */
#sidebar{
  background: var(--themeclr);
  width: 28%;
  max-width: 220px;
  height: calc(100vh - 72px);
  position: fixed;
  top: 72px;
  z-index: 9;
  /* transition: 0.5s; */
}
.footer {
  border-top: 1px solid #bbc2d2;
  background: #dce3f2;
}
.contactimg {
  width: 100%;
}
.noshrink{
  white-space: break-spaces !important;
}
#sidebar.compress {
  width: 50px;
}
#sidebar ul {
  margin-top: 33px;
}
#sidebar ul li {
  padding: 27px 10px 27px 15px;
  /* padding-left: 15px; */
}
.drop.dropend button:after {
  display: none;
}
.drop.dropend button {
  background: transparent;
  border: none;
  padding-left: 41px;
  position: relative;
  left: -39px;
  width: 100%;
  text-align: left;
}
#sidebar ul li:hover .drop.dropend button {
  color: #8ab8dc;
}
#sidebar ul li .drop.dropend button:focus {
  background: transparent !important;
  box-shadow: none;
}
.drop.show.dropend >div,.themedrop > div {
  background: #3191dbb3;
  z-index: 99999;
  border-radius: 15px;
  text-align: center;
}
.drop.show.dropend div a:hover,.drop.show.dropend div .dropdown-item:hover,
.themedrop > div a:hover {
  background: #3191db;
}
#sidebar ul li span{
  color: #fff;
}

div#sidebar ul li:hover {
  cursor: pointer;
}
div#sidebar li:hover span,div#sidebar li.active span{
  color: #8ab8dc;
}
#sidebar li img {
  width: 20px;
  margin-right: 10px;
}
#sidebar li .dropdown-item {
  color: #fff;
}
#sidebar.compress ul li  span {
  display: none;
}
button.hamburger {
  background: transparent;
  border: none;
  color: #fff;
}
.historyscroll
{
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden
}
.historyscroll.recenttrans
{
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden
}
button.hamburger img {
  width: 24px;
}
.logo {
  width: 200px;
  height:55px;
  margin-right: 15px;
  margin-left: 15px;
}
li.drop ul {
  position: absolute;
  background: #3191dba1;
  right: -18px;
  top: 0;
  color: #fff;
  border-radius: 10px;
  min-width: 110px;
  text-align: center;
}
li.drop {
  position: relative
}
li.drop ul li {
  padding: 8px !important;
  font-size: 14px;
}

.hidein991{
  display: block;
}
.showin991{
  display: none;
}
/* otp */
.confirm{
  display: flex;
  gap: 15px;
}
form.confirm input{
  display:inline-block;
  width:50px;
  height:50px;
  text-align:center;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #c8c8c8;
}
form.confirm input.otp:focus{
  box-shadow: none;
}
/* sign modadal */
.fileupload input {
  opacity: 0;
  cursor: pointer;
  width: 25px;
  height: 25px !important;
}
/* .editimg img {
  width: 100%;

  border-radius: 50%;
} */
.editimg img {
  width: 100px;
  border-radius: 50%;
  height: 100px;
} 
.editimg {
  position: relative;
  height: 100px;
  aspect-ratio: 1/1;
}
.editimg span, .fileupload input {
  position: absolute;
  color: white;
  background: #f40b5e;
  padding: 6px;
  font-size: 13px;
  border-radius: 50%;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.close-btn {
  position: absolute;
  right: -8px;
  background: #092e87;
  color: #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  top: -8px;
  border-color: #092e87;
  box-shadow: 0 0 9px 1px #ffffff;
  z-index: 9;
}
.signup{
  border-radius: 0 15px 15px 0;
}
.signin input {
  background: #0e2048 !important;
  border-color: #0e2048 !important;
  color: #afafaf !important;
  font-size: 14px;
}
.signin button.fa {
  background: #0e2048 !important;
  border-color: #0e2048 !important;
}
.signin input::placeholder{
  color: #afafaf !important;
}
.signin input,.signup input{
  border-radius: 10px;
}
button.inputbtn {
  background: #dce3f3;
  border-radius: 30px;
  border-left: 0;
  color: gray;
  font-size: 13px;
  padding-top: 9px;
  border-color: #ced4da;
}
.doclists button {
  border: transparent;
  color: #fff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  font-size: 12px;
}
.doclists .progress {
  height: 5px;
}
.doclists li:hover {
  box-shadow: -1px 3px 9px 1px #7eb8e4;
  padding: 10px;
  border-radius: 10px;
}
.upimg {
  background: #f3f6fd;
  padding: 10px;
  text-align: center;
}
.upimg p button {
  display: inline-block;
  width: 94px;
  height: 23px;
  overflow: hidden;
  border: none;
  box-shadow: none;
  background: transparent;
  color: #5ba5e0;
  font-weight: 700;
}
.paystatus .fa {
  font-size: 55px;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  padding: 14px;
  border: 2px solid white;
  margin-top: 28px;
  margin-bottom: 20px;
}
.profileimg {
  border-radius: 15px 15px 0 0;
}
.balance p span {
  font-weight: 700;
  font-size: 21px;
}
.profileimg img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-top: 14px;
  margin-bottom: 5px;
}
.profileimg h2{
  margin-top: 20px;
  font-weight: 600;
}
.selectdoc select {
  background-color: #3191db !important;
  color: #fff;
}
.selectdoc select option {
  background: #fff;
  color: #000;
}
.selectbtn {
  display: inline-block;
  position: relative;
  width: 97px;
  overflow: hidden;
  height: 17px;
  
}
.selectbtn input {
  position: absolute;
  top: 0;
  opacity: 0;
}
.upimg .fa {
  font-size: 89px;
  color: #d0e3f7;
}
.dashedbord{
  border: 1px dashed #cfdbf3;
  border-radius: 10px;
}
.doclists li {
  padding: 10px;
  margin-bottom: 15px;
}
.inputbtn:hover,.inputbtn:focus{
  background:#dce3f3;
  border-color: #ced4da;
  box-shadow: none;
}

.editprofmodal input {
  border-right: 0;
}
.graybox{
  background: #f3f6fd;
}
.signin {
  color: #fff;
}
.thememodal .modal-content{
  border-radius: 15px;
}
/* cont */

#homeheader button.navbar-toggler:focus {
  box-shadow: none;
  border: none;
}
#cont{
  background-color: #e6efff;
  width: calc(100% - 220px);
  margin-left: auto;
  min-height: 100vh;
  margin-top: 72px;
  overflow: hidden;
  /* transition: 0.5s; */
}
#cont.expand {
  width: calc(100% - 50px);
}
.whitebox {
  background: white;
  border-radius: 15px;
  padding: 20px 30px;
  margin-top: 15px;
}
.boxhead{
  font-size: 15px;
}
.reffincome img {
  width: auto;
  height: 36px;
}
.reffincome h5 {
  font-weight: 700;
  margin-bottom: 0;
}
.reffid{
  display: flex;
  align-items: center;
  height: 100%;
}
.reffid span:first-child {
  flex-shrink: 0;
  font-size: 15px;
  font-weight: 600;
}
.reffid span:last-child {
  width: 100%;
  background: #dbe4f2;
  color: #0063fc;
  display: inline-block;
  border-radius: 15px;
  padding: 5px 11px;
  margin-left: 10px;
  font-size: 14px;
}
.badge.badge-theme {
  background: var(--themeclr);
  border-radius: 10px;
  font-size: 12px;
}
.security li{
  display: flex;
}
.security li h5{
  color: var(--graytxt);
  font-size: 15px;
}
.security li img {
  height: 28px;
  margin-right: 10px;
}
.coinbal img {
  height: 45px;
}
.d-none-767{
  display: block;
}
.d-block-767 {
  display: none;
}
.mobilelogo {
  height: 39px;
}
.walletbalance img {
  width: 30px;
  height: 30px;
}
.logocont{
  position: relative;
  z-index: 1;
}
.logobg {
  position: absolute;
  right: -20px;
  bottom: -49px;
  z-index: -1;
}
.flex-1 > * {
  flex: 1;
}
.small-input input, .small-input select {
  height: 30px;
  border-radius: 30px;
  font-size: 14px;
  background-color: #dce3f3;
}
.inputparg {
  justify-content: space-between;
  background: #9aa1ab;
  border-radius: 30px;
  font-size: 14px;
  height: 30px;
  align-items: center;
  padding: 12px;
  color: white;
}
.qrimg{
  height: 130px;
  width: 130px;
}
.nounder button{
  color: white;
}
.compress .drop.dropend > button,.compress li:hover .drop.dropend > button {
  color: transparent !important;
  width: 50px;
}


/* Home */
.transbtn.profimg:hover,.transbtn.profimg:focus {
  background-color: transparent !important;
  box-shadow: none;
}
#homeheader .navbar-toggler-icon {
  filter: invert(1);
}
.btn.btn-bord:hover,.btn.btn-bord:focus {
  border: 1px solid #edd10a;
  background: transparent !important;
  color: #edd10a !important;
}
.btn.btn-bord.nav-link {
  border: 1px solid #edd10a;
  background-image: linear-gradient(to right, #d1b90d 30%,#d1bc16 50%, #d1ba10 80%);
  border-radius: 22px;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1.5;
  height: 34px;
  margin-top: 5px;
  margin-left: 10px;
  color: #fff;
}
.navbar-nav .btn.btn-bord.nav-link{
  max-width: 92px;
}
#homeheader {
  background: #0a338d;
  border-bottom: 1px solid #e8ebf41c;
  height: 85px;
}
#homeheader a {
  color: #F3F2F2;
  display: flex;
  align-items: center;
  font-size: 15px;
}
#homeheader a:hover
{
  color:#edd10a;
}
#homeheader .navbar-nav a img {
  width: 15px;
}
#homeheader .dropdown a:after {
  display: none;
}
#homeheader .navbar{
  /* height: 85px; */
  /* padding-top: 24px; */
}
.themetextbox textarea{
  background: #dce3f3;
  border-radius: 30px;
}
textarea {
  resize: none;
}
.supportedcurrecy ul li {
  text-align: center;
}
.supportedcurrecy ul li img {
  width: 55px;
  height: 55px;
}
.supportedcurrecy ul li p {
  color: var(--themeclr);
  font-weight: 700;
  margin-top: 5px;
}
.supportedcurrecy ul {
  display: flex;
  justify-content: space-evenly;
  max-width: 833px;
  margin: auto;
}
.supportedcurrecy ul li {
  text-align: center;
}
.banner {
  background-image: url("../src//Assets/Images/banner_blue.jpg");
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner h1 {
  font-size: 60px;
  text-transform: uppercase;
}
.banner h1 span {
  font-weight: 600;
}
.homebg{
  background-color: #dce3f2;
}
.tmcfeature .whitebox{
  height: 100%;
}
.tmcfeature img.icon_compens {
  max-width: 150px;
  min-height: 42px;
}
.viewuser .small-input span#basic-addon1 {
  height: 30px;
  border-radius: 30px 0 0 30px;
  background: #dce3f3;
  color: gray;
  font-size: 14px;
}
.viewuser .d-flex input {
  max-width: 300px;
}
.newpassword .inputbtn {
  margin: 0 !important;
    font-size: 13px;
    height: unset;
    width: unset;
    padding: 6px 11px;
    border: 1px solid #ced4da;
    border-left: 0;
    border-radius: 0 30px 30px 0 !important;
}
.docheight {
  height: 145px;
  overflow: auto;
}
.docheight > div{
  margin-bottom: 15px;
}
/* page */
#page{
  display: flex;
}
.exchange input {
  background: transparent;
  border: none;
}
.exchange .fa.fa-arrow-down {
  padding: 8px;
  border: 1px solid #000;
  margin: 18px;
  border-radius: 50%;
}
.exchange button img{
  height: 20px;
  width: 20px;
}
.token-lists {
  height: 260px;
  overflow: auto;
}
.exchange .input-group button:focus {
  box-shadow: none;
}
ul.token-lists li img {
  height: 25px;
  width: 25px;
}
.exchange  button.fa.fa-exchange {
  background: transparent;
  border: none;
}
.exchange button {
  font-size: 14px;
}
.exchange .input-group button {
  border-color: lightgray;
  border-radius: 5px !important;
}
.income .whitebox{
  height: 100%;
}
.token-lists li {
  padding: 15px;
}
ul.token-lists li:hover {
  background: #ededed;
  cursor: pointer;
}
.login .text-center img {
  height: 34px;
}
.login {
  background-color: #092e87;
}
.sidebar-scroll{
  max-height: calc(100vh - 66px);
  overflow-y: auto;
}
.withdrawpass .fa-eye-slash ,.withdrawpass .fa-eye-slash:hover
,.withdrawpass .fa-eye-slash:focus,.withdrawpass .fa-eye ,.withdrawpass .fa-eye:hover
,.withdrawpass .fa-eye:focus {
  border-color:#ced4da !important;
  background: #fff;
  color: #6c757d;
  box-shadow: none;
}
@media(max-width:1199px){
  .scanqr{
    height: 500px !important;
  }
  .signin.w-35 {
    width: 47%;
  }
}
@media(max-width:991px){
  #homeheader .navbar {
    height: 85px;
  }
  .historyscroll
{
  max-height: 300px;
}
  .showin991{
    display: block;
  }
  .editprofile  {
    width: 100%;
    border-radius: 0 0 12px 12px;
    height: 50px;
    padding-bottom: 20px;
  }
  .editprofile:focus{
    box-shadow: none;
  }
  .blue.editprofile {
    background: #3191db;
    color: #fff;
  }
  .white.editprofile{
    background: #fff;
    color: #3191db;
  }
.hidein991{
  display: none;
}
.showin991{
  display: block;
}
  .editprof,.signup{
    border-radius:  0 0 15px 15px;
  }
  #signmodal .signup , #signmodal .editprof{
    border-radius:  15px 15px 15px 15px;
  }
  .signin.w-35 {
    width: 100%;
  }
  .editprofmodal .w-50 {
    width: 100% !important;
  }
  #homeheader div#basic-navbar-nav {
    background: #4774e4;
    padding: 20px;
    
  }
  .banner {
    background-position: right;
  }
  .banner h1 {
    font-size: 45px;
  }
  
  #sidebar {
    max-width: 175px;
  }
  #cont {
    width: calc(100% - 174px);
  }
  div#header .hamexp:after,div#header .hamcomp:after {
    /* max-width: 175px; */
    display: none;
  }
  .logo {
    /* width: 124px; */
    height: 40px;
    margin-left: 3px;
  }

  button.hamburger {
    width: 72px;
  }
  button.hamburger img {
    width: 15px;
  }
}
@media(max-width:767px){
  #homeheader a.navbar-brand
  {
    margin-bottom: 0px !important;
  }
 .join{
  display: none;
 }
  .signup {
    border-radius: 0 0 15px 15px;
}
  .reffincome.mobilejc{
    justify-content: unset;
  }
  .tmcfeature .whitebox p {
    font-size: 14px;
  }
  .banner h1 {
    font-size: 25px;
  }
  .banner {
    padding-top: 80px;
    background-position: right;
    background-size: cover;
}
  .qrimg {
    height: 100px;
    width: 100px;
}
  .d-none-767{
    display: none;
  }
  .d-block-767{
    display: block;
  }
  .search {
    display: none;
  }
  button.hamburger {
    width: 45px;
  }
}
@media(max-width:575px){
  .logo {
    height: 33px;
    width: 140px;
  }
  .card_banner_title
  {
    font-size: 20px !important;
  }
  .row_scale .col-md-4.bg_blue_card_sec
  {
    transform: scale(1) !important;
    width: 100% !important;
  }
  form.confirm input {
    width: 35px;
    height: 35px;
  }

  .inputparg.mt-3.mb-0 {
    background: transparent;
    color: black;
    height: auto;
  }
  .inputparg span {
    display: block;
}
  .container {
    padding-left: 20px;
    padding-right: 20px;
}

  .supportedcurrecy ul li {
    margin-right: 15px;
  }

 
  .supportedcurrecy ul li img {
    width: 45px;
    height: 45px;
  }
  .supportedcurrecy ul li p {
    font-size: 12px;
}
  #sidebar ul li {
    padding: 10px 10px 10px 15px;
  }
  .whitebox {
    padding: 19px 13px;
}

  .expand .drop.dropend > button,.expand li:hover .drop.dropend > button {
    color: transparent !important;
    width: 50px;
  }
  .compress .drop.dropend > button,.compress li:hover .drop.dropend > button {
    color: #fff !important;
    width: 50px;
  }
  #sidebar.compress{
    max-width: 100%;
    width: 100%;
    z-index: 9;
  }
  #sidebar.expand {
    /* width: 50px; */
    width: 0;
    z-index: 9;
  }
  #sidebar.expand li img{
    display: none;
  }
  #sidebar.expand  li span {
    display: none;
  }
  #sidebar.compress  li span {
    display: inline-block !important;
  }
  #cont {
    /* width: calc(100% - 50px); */
    width: 100%;
  }
  .reffincome .aqua {
    font-size: 16px;
  }
  .reffincome img {
    height: 25px;
  }
  ul.coinbal li {
    display: inline-block;
  }
  ul.coinbal  {
    white-space: nowrap;
  }
  .h84-575{
    height: 84px !important;
  }
  ul.coinbal li {
    margin-right: 15px;
  }
  ul.coinbal li p{
    margin-bottom: 0;
  }
}
@media(max-width:420px){
  .leftcont{
    display: none;
  }
  .rightcont{
    width: 100%;
  }
  .f-10-420{
    font-size: 10px;
  }
  .supportedcurrecy ul {
    max-width: 360px;
    overflow-x: auto;
    justify-content: unset;
  }
}
@media(min-width:767px){
  .w-sm-50{
    width: 50%;
  }
}

.mobile_reg
{
  display: none;
}

.opa_zero
{
  opacity: 0;
}
.badge_blue
{
  background-color: #4774e4;
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 25px;
  font-weight: 300 !important;
}
/* .home_font
{
  font-family: 'Roboto', sans-serif !important;
} */
.banner_p_txt
{
color:#fff;
font-size: 14px;
}
.btn_home_banner
{
  max-width: 120px;
}
.card_home_ban
{
  border-radius: 10px;
}
.card_banner_title
{
  color:#000000;
  font-size: 25px;
  text-align: center;
  font-weight: 600;
}
.card_banner_desc span
{
  color:#777777;
  font-weight: 600;
  font-size: 18px;
}
.card_banner_desc img
{
  max-width: 20px;
}
.left_sec_banner
{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.left_sec_banner .badge_blue
{
  max-width: 120px;
}
.rowupper_banner
{
  margin-top: -70px;
}
.blue_text_tile
{
  color:#092E87;
  font-size: 35px;
  font-weight: 700;
}
.homebg_white
{
  background-color: #fff;
}
.card_home_ban
{
  border:none;
  -webkit-box-shadow: 0 0 16px 5px rgb(147 147 147 / 20%);
  -moz-box-shadow: 0 0 16px 5px rgb(147 147 147 / 20%);
  box-shadow: 0 0 16px 5px rgb(147 147 147 / 20%);
}
.card_banner_title_blue
{
  color:#092E87;
  font-size: 14px;
  font-weight: 500;
}
.card_banner_title.text-left
{
  text-align: left !important;
}
.banner_title_blk
{
  color:#676565;
  font-size: 14px;
}
.text-justify
{
  text-align: justify !important;
}
.card_about p
{
  font-size: 12px !important;
}
.card_about img
{
  max-width: 15px;
}
.card_about
{
  border-radius: 8px !important;
}
.img_coin_new
{
  max-width: 70px !important;
}
.card_banner_title_sm
{
  color:#4D4D4D;
  font-size: 18px !important;
  text-align: left;
  font-weight: 600;
}
@media only screen and (min-width:768px)
{
  .bg_blue_card_sec .bor_right_md
  {
  border-color:#e8ebf41c !important;

  }
  .sec_normal .card_left
  {
    border-radius: 25px 0px 0px 25px !important;
  }
  .sec_normal .card_right
  {
    border-radius: 0px 25px 25px 0px !important;
  }
  .bor_right_md
  {
    border-right: 1px solid #ccc;
  }
  .row_scale .col-md-4.sec_normal
{
  width: 30% !important;
}
.row_scale .col-md-4.bg_blue_card_sec
{
  width: 36% !important;
}
.row_scale .col-md-4.bg_blue_card_sec .card.card_concept
{
  min-height: 420px !important;
  margin-top: -10%;

}
.row_scale 
{
  margin-top: 100px !important;
}

}
.bg_grey_tick
{
  background-color: #777777;
  width: 12px;
    height: 12px;
    color: #fff;
    border-radius: 50%;
    font-size: 10px !important;
    padding: 0px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bg_grey_tick .fa
{
  font-size: 8px !important;

}
.bg_text_sm_grey
{
  color:#777777;
  font-size: 10px;
  font-weight: 500;
}
/* .card_grey
{
  background-color: #e1e4e985;
  color:#A4A1A1;
  font-size: 12px;
  border-radius: 8px;
  border: none;
  text-align: center;
} */

.card_grey
{
  background-color: #688ECD;
  color:#FFF;
  font-size: 12px;
  border-radius: 8px;
  border: none;
  text-align: center;
}
.card_grey p
{
  font-size: 13px;

}
.card_concept
{
  border-radius: 25px !important;
}
.bg_blue_card_sec .card_concept
{
  background-color: #092E87 !important;
}
/* .bg_blue_card_sec
{
  transform: scaleX(1.1) scaleY(1.5);
  z-index: 1;

} */

.row_scale .col-md-4.bg_blue_card_sec
{
z-index: 1;
transform: scale(1.2);
width: 34% !important;
}
.row_scale
{
  justify-content: space-between;
}
.card_concept .card_banner_title_blue
{
  font-size: 13px!important;
}
.bg_blue_card_sec .card_concept
{
  box-shadow: 0 0 10px 2px rgb(9 46 135 / 66%);
}
.bg_blue_card_sec .card_concept .card_banner_title,
.bg_blue_card_sec .card_concept .card_banner_title_blue 
{
color:#fff !important;
}
.card_tarn
{
  background-color: transparent !important;
}
.bg_blue_card_sec .card_tarn
{
  border-color:#e8ebf41c !important;
}
.bg_blue_card_sec .card_tarn .card_banner_title_blue
{
color:#3563D1 !important;
}
.bg_blue_card_sec .card_banner_title_sm 
{
  color:#fff;
}
.bg_blue_card_sec .bg_text_sm_grey
{
  color:#FFFFFF !important;
}
.bg_blue_card_sec .bg_grey_tick
{
  background-color: #fff !important;
  color:#3563D1 !important;
}
.bg_blue_card_sec .card_grey
{
  background-color: #769BD8E0 !important;
  color:#FBFBFB !important;
}
.icon_compens
{
  filter: brightness(0);
}
.card_comp_title
{
  color:#3D3B3B;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 20px;
}
.row_compens .bg_text_sm_grey
{
  font-size: 12px !important;
}
.row_compens .card_home_ban .d-flex
{
align-items: baseline;
}
.row_compens .card_home_ban
{
  height: 100%;
  cursor: pointer;
}
.row_compens .card_home_ban:hover
{
  background-color: #092E87 !important;
}
.row_compens .card_home_ban:hover img
{
  filter: brightness(0) invert(1);
}
.row_compens .card_home_ban:hover .card_comp_title
{
color:#fff;
}
.row_compens .card_home_ban:hover .bg_grey_tick {
  background-color: #fff !important;
  color: #3563D1 !important;
}
.row_compens .card_home_ban:hover .bg_text_sm_grey
{
  color:#fff !important;
}
.font_weight_700
{
  font-weight: 700 !important;
}
.card_mining img
{
  max-width:25px;
}
.bold_letter
{
  color:#3D3B3B;
  font-weight: 700;
}
.thin_letter
{
  color:#777777;
  font-weight: 500;
}
.link_cus
{
  cursor: pointer;
}
.hamexp .logo
{
  width: 130px;
    height: 27px;
}



.footer_text
{
  color:#fefefe;
  font-size: 12px;
  text-align: justify;
  line-height: 2;
}
.footer_sec
{
  background-color: #193d4d;
}
#footer .form_contact .form-control,
#footer .form_contact .input-group-text
{
background-color: transparent !important;
border-color:#e8ebf41c !important;
}
.footer_news_row .btn
{
  max-height: 45px !important;
  padding: 10px 25px !important;
  background-color: #fff !important;
  border:1px solid #fff;
  color: #092E87;
  border-radius: 10px !important;
  font-weight: 500;
}
.footer_news_row .btn:hover
{
  background-color: transparent !important;
  color: #fff;
  border:1px solid #fff;

}
.list_inline_social
{
  display: flex;
  align-items: center;
}
.li_inline_social
{
  margin-right: 10px;
}
.li_inline_social:last-child
{
  margin-right: 0px !important;
}
.li_inline_social a
{
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  background-color: #fff;
  color:#092E87;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
}
.li_inline_social a:hover
{
  background-color: #8AA3E1;
  color:#000000; 
}
.footer_title
{
  color:#fefefe;
  font-size: 18px;
  font-weight: 700;
}
.footer_menu a
{
color:#fefefe !important;
}
.footer_menu a:hover
{
  color:#edd10a !important;
}
#footer img
{
  max-width: 200px;
}
.link_col
{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.hr_white
{
  background-color: #fefefe !important;
}
.footer_outer
{
  background-color: #092E87 !important;
}

.footer_menu li a
{
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
}
.home_font .container
{
  max-width: 92% !important;
}

.form_contact label
{
  color:#e8ebf41c;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}
.form_contact .input-group-text
{
border-right: none !important;
border-top-right-radius: 0px;
border-bottom-right-radius: 0px;
border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
background-color: transparent;
height: 45px;
border-color: #e0e0e0 !important;
outline: -webkit-focus-ring-color auto 0px !important;
color:#627cbc !important;

}
.form_contact  .form-control
{
  border-left: none !important;
  border-top-right-radius: 10px;
border-bottom-right-radius: 10px;
box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%) !important;
border-color: #e0e0e0 !important;
outline: -webkit-focus-ring-color auto 0px !important;
background-color: transparent;
color:#627cbc !important;


}
.form_contact .input-group
{
  border-radius: 10px;
border-color: #e0e0e0 !important;
outline: -webkit-focus-ring-color auto 0px !important;

}
.form_contact .input-group:hover .input-group-text,
.form_contact .input-group:hover .form-control,
.form_contact .form-control:hover,.form_contact .form-control:hover
{
outline: -webkit-focus-ring-color auto 0px !important;
border-color: #e8ebf41c !important;
}
.form_contact .form-control:focus
{
border-color: #e0e0e0 !important;
outline: -webkit-focus-ring-color auto 0px !important;

}
.form_contact .form-control::placeholder
{
  color:#627cbc !important;
}
.sponsor_img
{
  margin-top: -100px;
}
.spon_sec_cen
{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
@media(max-width:767px){
  .card_banner_title
  {
    font-size: 18px !important;
  }
  .row_scale .col-md-4.bg_blue_card_sec
  {
    transform: scale(1) !important;
    width: 100% !important;
  }
.row_scale .col-12.sec_normal,
.row_scale .col-12.bg_blue_card_sec 
{
  margin-bottom: 20px !important;
}
.row_scale .card_concept 
{
padding-top: 15px !important;
padding-bottom: 30px !important;
}
.card_comp_title
{
  font-size: 18px !important;
}
.sponsor_img
{
  margin-top: -50px !important;
}
.row_rev
{
  flex-direction: column-reverse;
  padding-bottom: 3rem !important;
}
.blue_text_tile
{
  font-size: 27px !important;
}
.footer_news_row .col-8,.footer_news_row .col-4
{
  flex: 0 0 auto;
    width: 100% !important;
}
.footer_news_row .col-4
{
  padding-left: calc(var(--bs-gutter-x) * .5) !important;
}
.list_inline_social
{
  margin-top: 20px !important;
}
#homeheader a
{
  font-size: 14px !important;
  margin-bottom:15px;
}
.navbar-nav .btn.btn-bord.nav-link
{
  margin-left: 0px;
}
}

@media only screen and (min-width:768px) and (max-width:991px)
{
  .row_scale .col-md-4.bg_blue_card_sec .card.card_concept
  {
    min-height: 110% !important;
  }
  .banner
  {
    background-size: cover;
  }
  .banner h1
  {
    font-size: 35px !important;
  }
  .left_img_cen
  {
    display: flex;
    align-items: center;
  }
  .card_banner_title
  {
    font-size: 14px !important;
  }
  .card_banner_title_sm
  {
    font-size: 10px !important;
    margin-top: 10px !important;
  }
  .row_rev
  {
    padding-bottom: 5rem !important;
  }
  .sponsor_img
  {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width:992px) and (max-width:1199px)
{
  .banner h1
  {
    font-size: 45px !important;
  }
  .banner
  {
    background-size: cover !important;
  }
  .left_img_cen
  {
    display: flex;
    align-items: center;
  }
  .sponsor_img
  {
    margin-top: 0px !important;
  }
  .row_scale .col-md-4.bg_blue_card_sec .card.card_concept
  {
    margin-top: -10% !important;
    min-height: 115% !important;
  }
  .card_banner_title_sm
  {
    font-size: 15px !important;
  }
  .row_scale
  {
    margin-top: 100px !important;
  }
  .card_banner_title
  {
    font-size: 20px !important;
  }
}
.bg_text_md_grey
{
  font-weight: 700;
}

.row_compens .card_home_ban:hover .bg_text_md_grey
{
color:#fff !important;
}
.card_home_ban
{
  cursor: pointer;
}
.card_home_ban:hover
{
  box-shadow: 0 0 20px 8px rgb(147 147 147 / 48%) !important;
}
.card_concept .card_banner_title_blue.text_big_blu
{
  font-size: 13px !important;
}
.text-right
{
  text-align: right !important;
}

@media only screen and (min-width:1501px) and (max-width:1600px)
{
  .row_scale .col-md-4.bg_blue_card_sec .card.card_concept
{
  min-height: 118% !important;

}
}

@media only screen and (min-width:1601px) and (max-width:1700px)
{
  .row_scale .col-md-4.bg_blue_card_sec .card.card_concept
{
  min-height: 115% !important;

}
}
@media only screen and (min-width:1701px) and (max-width:1800px)
{
  .row_scale .col-md-4.bg_blue_card_sec .card.card_concept
{
  min-height: 110% !important;

}
}
@media only screen and (min-width:1801px) and (max-width:1900px)
{
  .row_scale .col-md-4.bg_blue_card_sec .card.card_concept
{
  min-height: 108% !important;

}
}

@media only screen and (min-width:1901px)
{
  .row_scale .col-md-4.bg_blue_card_sec .card.card_concept
{
  min-height: 108% !important;

}
}